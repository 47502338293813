@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');


.contenedorApp {
	width: 100vw;
}

.contenedorGeneradorContraseñas {
	display: flex;
    align-items: center;
	justify-content: center;
    flex-direction: column;
	height: 76vh;
}

label {
	line-height: 40px;
	text-align: center;
	color: #fff;
	font-size: 26px;
	letter-spacing: 3px;
}

input {
	text-align: center;
	border: none;
}

.fila {
	margin-bottom: 30px;
	padding-top: 19px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	justify-items: center;
}

.numeroGenerado {
	line-height: 40px;
    background: #277d3c;
	padding: 0px 40px;
	border-radius: 4px;
	color: #fff;
	cursor: default;
}

.contenedorInputCopy {
	position: relative;
}

.botonCopiar {
	position: absolute;
    background: #277d3c;
    border-radius: 5px;
    padding: 3.6px;
    right: -50px;
    top: 0px;
}

.botonCopiar:hover {
	background: #2eab4d;
}

.botonCopiar svg {
	color: #fff;
    cursor: pointer;
    width: 30px;
    height: 30px;
}

.mensajeExito {
	color: #02a802;
	font-weight: 600;
	font-size: .8em;
	text-align: center;
}

@media (max-width: 740px) {
	.formularioGeneradorContraseñas {
		position: relative;
    	top: 0px;
	}

	label {
		font-size: 16px;
		letter-spacing: 2px;
	}

	.fila {
		margin-bottom: 20px;
		padding-top: 10px;
		grid-template-columns: 1fr;
	}
}