html {
  height: 100%;

  --image-gradient: linear-gradient(
    to bottom right,
    rgba(12, 10, 22, 0),
    rgba(123, 255, 0, 0.897)
  );
}

body {
  background-image: var(--image-gradient), url(https://i.pinimg.com/originals/db/3f/ee/db3fee314726f948416cde8a622eb11a.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-blend-mode: hue;

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}