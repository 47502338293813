.navbar {
    display: flex;
    justify-content: space-between;
}

.iconPortfolio > a > img {
    width: 100px;
    height: auto;
    border-radius: 50px;
    margin: 5px 0px 0px 20px;
    cursor: pointer;
}

.banderas button{
    margin: 5px 20px 0px 0px;
    background: none;
    border: none;
}

.banderas > button > img {
    width: 80px;
    height: auto;
    border-radius: 10px;
    cursor: pointer;
}

@media (max-width: 740px) {
    .iconPortfolio > a > img {
        width: 70px;
        margin: 5px 0px 0px 10px;
    }

    .banderas button{
        margin: 5px 10px 0px 0px;
    }
    
    .banderas > button > img {
        width: 40px;
    }
}

@media (max-width: 520px) {
    .banderas {
        display: flex;
    }
}

@media (max-width: 400px) {
    .iconPortfolio > a > img {
        width: 50px;
        margin: 5px 0px 0px 5px;
    }

    .banderas button{
        margin: 5px 5px 0px 0px;
    }
    
    .banderas > button > img {
        width: 30px;
    }
}