.footer {
	position: absolute;
  bottom: 0;
  width: 100%;
}


.copyright {
  background: transparent;
  width: 100%;
  color: #fff;
  display: flex;
  justify-content: center;
}

.copyright > p {
	font-size: 1em;
  margin: 5px 0px 0px 0px;
  text-align: center;
  display: inline-block;
  padding: 0px 3px;
}

@media (max-width: 540px) {

  .copyright {
    padding: 0px 0px 6px 0px;
  }
  
  .copyright > p {
    font-size: 11px;
  }
}